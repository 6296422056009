import { Button, SimpleGrid, TextInput, Text, Anchor, Stack } from '@mantine/core';
import { extract, token_set_ratio } from 'fuzzball';
import { closeAllModals } from '@mantine/modals';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { $TsFixMe } from '../../../../module';
import {
  CreatePolicyholderInputSchema,
  UpdatePolicyholderInputSchema,
} from '../../../graphql/inputs.generated';
import {
  UpdatePolicyholderMutation,
  useCreatePolicyholderMutation,
  usePolicyholdersNamesQuery,
  useUpdatePolicyholderMutation,
} from '../../../graphql/operations/policyholders.generated';
import { UpdatePolicyholderInput } from '../../../graphql/types.generated';
import { feinFormatterParser } from '../../../utils/form';
import onApolloError from '../../../utils/utils';
import states from '../../../utils/states';
import { showNotification } from '@mantine/notifications';
import AutofillAddressFields, {
  ADDRESS_1_FIELDNAME,
  CITY_FIELDNAME,
  ZIPCODE_FIELDNAME,
} from '../Fields/AutofillAddressFields';
import Link from 'next/link';
import { isNil } from 'lodash';
import EmailField from '../Fields/EmailField';
import RadioYesNo from '../../Fields/RadioYesNo';
import { namedOperations } from '../../../graphql/namedOperations.generated';
import { useRouter } from 'next/navigation';

type PolicyholderDetailsFormProps = {
  id?: string;
  defaultValues?: $TsFixMe;
  onUpdate?: ((data: UpdatePolicyholderMutation) => void) | undefined;
};

export const stateValues = states
  .filter((state) => !state.territory)
  .map((state) => {
    return { value: state.abbreviation, label: state.name };
  });

const PolicyholderDetailsForm = ({
  id,
  defaultValues = {},
  onUpdate,
}: PolicyholderDetailsFormProps) => {
  const { data } = usePolicyholdersNamesQuery();

  const router = useRouter();
  const [createPolicyholder] = useCreatePolicyholderMutation({
    onCompleted: (response) => {
      if (response?.createPolicyholder?.id) {
        showNotification({ message: 'Policyholder created' });
        router.push(`/u/policyholders/${response?.createPolicyholder?.id}`);
        closeAllModals();
      }
    },
    refetchQueries: [
      namedOperations.Query.PolicyholdersV2,
      namedOperations.Query.PolicyholdersNames,
    ],
    onError: onApolloError(),
  });
  const [updatePolicyholderDetails] = useUpdatePolicyholderMutation({
    onCompleted: (response) => {
      closeAllModals();
      onUpdate?.(response);
    },
    onError: onApolloError(),
    refetchQueries: [namedOperations.Query.Policyholder, namedOperations.Query.SelectedForms],
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, dirtyFields },
    setValue,
    setFocus,
    control,
    watch,
  } = useForm<UpdatePolicyholderInput>({
    defaultValues: defaultValues,
  });
  const requiredFields = [ADDRESS_1_FIELDNAME, CITY_FIELDNAME, ZIPCODE_FIELDNAME];

  const overlappingPolicyholders = extract(watch('name'), data?.policyholdersNames || [], {
    returnObjects: true,
    processor: (choice) => choice.name,
    cutoff: watch('name') ? 80 : 0,
    full_process: true,
    scorer: token_set_ratio,
  });
  const policyholderNamesError =
    dirtyFields.name && overlappingPolicyholders.length && watch('name') ? (
      <Text c="orange" size="xs">
        Did you mean:{' '}
        {overlappingPolicyholders.map((item, index) => (
          <Fragment key={item.choice.id}>
            <Link legacyBehavior href={`/u/policyholders/${item.choice.id}`} passHref>
              <Anchor size="xs">{item.choice.name}</Anchor>
            </Link>
            {index < overlappingPolicyholders.length - 1 && <>, </>}
          </Fragment>
        ))}
      </Text>
    ) : null;

  return (
    <form
      onSubmit={handleSubmit((formData) =>
        id
          ? updatePolicyholderDetails({
              variables: {
                input: UpdatePolicyholderInputSchema().parse({
                  ...formData,
                  id,
                }),
              },
            })
          : createPolicyholder({
              variables: {
                input: CreatePolicyholderInputSchema().parse({
                  ...formData,
                }),
              },
            })
      )}
    >
      <SimpleGrid cols={1}>
        <Stack gap="xs">
          <TextInput
            error={errors.name && errors.name.message}
            {...register('name', {
              required: 'Required',
              validate: (value) => /^(?!.*test).*$/i.test(value) || 'name not allowed',
            })}
            label="Name"
            required
          />
          {policyholderNamesError}
        </Stack>

        <AutofillAddressFields<UpdatePolicyholderInput>
          register={register}
          errors={errors}
          setValue={setValue}
          setFocus={setFocus}
          control={control}
          dirtyFields={dirtyFields}
          newRecord={isNil(id)}
          stateDirtyField
          requiredFields={requiredFields}
        />
        <TextInput
          error={errors.fein && errors.fein.message}
          {...register('fein')}
          label="FEIN"
          {...feinFormatterParser}
        />
        <TextInput
          error={errors.website && errors.website.message}
          {...register('website')}
          type="url"
          label="Website"
        />
        <TextInput
          error={errors.insuredContactName && errors.insuredContactName.message}
          {...register('insuredContactName')}
          label="Insured Contact Full Name"
        />
        <EmailField name="insuredContactEmail" label="Insured Contact Email" control={control} />
        <RadioYesNo control={control} name="hasAutodesk" label="Autodesk Customer?" />
        <Button type="submit" fullWidth loading={isSubmitting}>
          Submit
        </Button>
      </SimpleGrid>
    </form>
  );
};

export default PolicyholderDetailsForm;
