import { MultiSelect, Select, TextInput, Text, Stack } from '@mantine/core';

import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldNamesMarkedBoolean,
  FieldValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';
import { $TsFixMe } from '../../../../module';
import { stateValues } from '../Submissions/PolicyholderDetailsForm';
import GooglePlacesAutocompleteField from './GooglePlacesAutocompleteField';

export const ADDRESS_1_FIELDNAME = 'address1';
export const ADDRESS_2_FIELDNAME = 'address2';
export const CITY_FIELDNAME = 'city';
export const STATE_FIELDNAME = 'state';
export const ZIPCODE_FIELDNAME = 'zipcode';
export const ADDITIONAL_STATES_FIELDNAME = 'additionalStates';

export type AddressFieldsProps<T extends FieldValues = any> = {
  errors: FieldErrors;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  setFocus: UseFormSetFocus<any>;
  control: Control<T, any>;
  dirtyFields: FieldNamesMarkedBoolean<any>;
  defaultValues?: $TsFixMe;
  additionalStates?: boolean;
  stateDirtyField?: boolean;
  newRecord?: boolean;
  requiredFields?: string[];
};

const AutofillAddressFields = <TT extends FieldValues>({
  register,
  errors,
  setValue,
  setFocus,
  control,
  dirtyFields,
  additionalStates,
  defaultValues = {},
  stateDirtyField = false,
  newRecord = false,
  requiredFields = [],
}: AddressFieldsProps<TT>) => {
  // @ts-ignore
  // const state = useWatch({ name: 'state', control });

  return (
    <>
      <GooglePlacesAutocompleteField setValue={setValue} setFocus={setFocus} />
      <TextInput
        error={errors.address1?.message?.toString()}
        {...register(ADDRESS_1_FIELDNAME)}
        label="Address Line 1"
        required={requiredFields?.includes(ADDRESS_1_FIELDNAME)}
      />
      <TextInput
        error={errors?.address2?.message?.toString()}
        {...register(ADDRESS_2_FIELDNAME)}
        label="Address Line 2"
        required={requiredFields?.includes(ADDRESS_2_FIELDNAME)}
      />
      <TextInput
        error={errors?.city?.message?.toString()}
        {...register(CITY_FIELDNAME)}
        label="City"
        required={requiredFields?.includes(CITY_FIELDNAME)}
      />
      <Controller
        // @ts-expect-error
        name={STATE_FIELDNAME}
        control={control}
        rules={{ required: requiredFields?.includes(STATE_FIELDNAME) }}
        render={({ field, fieldState }) => (
          <Stack gap={4}>
            <Select
              {...field}
              label="State"
              data={stateValues}
              required
              placeholder={defaultValues?.state}
              error={fieldState.error?.message}
              searchable
            />
            {stateDirtyField && dirtyFields.state && !newRecord && (
              <Text c="orange" size="xs">
                Changing state will affect coverage form selections. Please save an exposure in
                order to regenerate coverage form selections
              </Text>
            )}
          </Stack>
        )}
      />
      <TextInput
        error={errors?.zipcode?.message?.toString()}
        {...register(ZIPCODE_FIELDNAME, {
          pattern: {
            value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
            message: 'Invalid Zip Code',
          },
        })}
        label="ZIP Code"
        required={requiredFields?.includes(ZIPCODE_FIELDNAME)}
      />
      {additionalStates && (
        <Controller
          // @ts-expect-error
          name={ADDITIONAL_STATES_FIELDNAME}
          control={control}
          rules={{ required: requiredFields?.includes(ADDITIONAL_STATES_FIELDNAME) }}
          render={({ field, fieldState }) => (
            <MultiSelect
              {...field}
              label="Additional States"
              data={stateValues}
              placeholder={defaultValues?.additionalStates}
              error={fieldState.error?.message}
              searchable
            />
          )}
        />
      )}
    </>
  );
};
export default AutofillAddressFields;
