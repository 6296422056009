import { TextInput } from '@mantine/core';
import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { validateEmail } from '../../../utils/form';

const EmailField = <T extends FieldValues>({
  name,
  label,
  required,
  control,
}: {
  name: Path<T>;
  label: string;
  required?: boolean;
  control: Control<T, any>;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: validateEmail,
        required,
      }}
      render={({ field, fieldState }) => (
        <TextInput
          required={required ?? false}
          type="email"
          error={fieldState.error && fieldState.error.message}
          {...field}
          label={label}
          onChange={(value) => field.onChange(value || null)}
        />
      )}
    />
  );
};

export default EmailField;
