import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import formatStringByPattern from 'format-string-by-pattern';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isURL from 'validator/lib/isURL';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const validateEmail = (value: string) => {
  if (value) {
    return isEmail(value) ? undefined : 'Invalid email';
  }

  return undefined;
};

export const validateUrl = (value: string) => {
  if (value) {
    return isURL(value, { require_protocol: true }) ? undefined : 'Invalid url';
  }
  return undefined;
};

export const feinFormatterParser = {
  finalFormFormat: (value: string) => {
    if (value) {
      const onlyNumbers = String(value).replace(/[^\d]/g, '');

      return formatStringByPattern('XX-XXXXXXX', onlyNumbers);
    }

    return value;
  },
  finalFormParse: (value?: number | string | null) =>
    value ? String(value).replace(/-/g, '') : value,
  maxLength: 10,
  validate: (value?: number | string | null) => {
    if (value && feinFormatterParser.finalFormParse(value)) {
      // @ts-expect-error
      return isLength(feinFormatterParser.finalFormParse(value), { min: 9, max: 9 })
        ? undefined
        : 'Must be 9 characters';
    }

    return undefined;
  },
};
