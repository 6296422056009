import { Badge } from '@mantine/core';
import { isNil } from 'lodash';
import React from 'react';

export const TimeToIndicateBadge = ({
  timeToIndicated,
  isOverdue,
  indicationDueInHours,
  badgeSize,
}: {
  timeToIndicated: number | null | undefined;
  isOverdue: boolean;
  indicationDueInHours: number | null | undefined;
  badgeSize: string;
}) => {
  if (!isNil(timeToIndicated)) {
    return (
      <Badge size={badgeSize} color="dark" variant="outline">
        {timeToIndicated} hours
      </Badge>
    );
  }

  if (isOverdue) {
    return (
      <Badge size={badgeSize} color="red">
        OVERDUE
      </Badge>
    );
  }

  return (
    <Badge size={badgeSize} color="orange">
      DUE {indicationDueInHours} hours
    </Badge>
  );
};
