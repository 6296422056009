import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { FunctionComponent } from 'react';
import QuoteBadgeStatus from '../Underwriter/QuoteStatusBadge';
import Assignee from '../Assignee';
import Link from 'next/link';
import { Anchor } from '@mantine/core';
import { isNil } from 'lodash';
import {
  PaginatedPoliciesQuery,
  useMyOpenPoliciesQuery,
} from '../../graphql/operations/PolicyGrid.generated';
import 'ag-grid-enterprise';
import { autoSizeAll } from '../InputGrid/InputGrid';
import { EMPTY_ARRAY } from '../../constants';
import { useSession } from '../../hooks/useSession';
import { QuoteOpen } from './common';
import { QuoteStatus } from '../../graphql/types.generated';
import { TimeToIndicateBadge } from '../TimeToIndicateBadge';

type RowShape = PaginatedPoliciesQuery['paginatedPolicies'][0];

const MyOpenPoliciesGrid: FunctionComponent = () => {
  const session = useSession();

  const id = session?.session?.user?.id;
  const { data: myPoliciesQuery } = useMyOpenPoliciesQuery({
    variables: { assigneeId: id },
  });

  const colDef: ColDef<RowShape>[] = [
    {
      field: 'policyName',
      headerName: 'Policy Name',
      cellRenderer: (props: { data: RowShape }) => (
        <Link legacyBehavior href={`/u/policies/${props.data.policyId}`} passHref>
          <Anchor>{props.data.policyName}</Anchor>
        </Link>
      ),
    },
    {
      field: 'namedInsured',
      headerName: 'Named Insured',
      cellRenderer: (props: { data: RowShape }) => (
        <Link legacyBehavior href={`/u/policyholders/${props.data.policyHolderId}`} passHref>
          <Anchor>{props.data.namedInsured}</Anchor>
        </Link>
      ),
    },
    {
      field: 'brokerage',
      headerName: 'Brokerage',
    },
    {
      field: 'broker',
      headerName: 'Broker',
    },
    { field: 'effectiveDate', headerName: 'Effective Date', sortable: true },
    {
      field: 'assignee',
      headerName: 'Assignee',
      filter: false,
      menuTabs: [],
      cellRenderer: (props: { data: RowShape }) => (
        <Assignee imageUrl={props.data.assignee?.imageUrl} name={props.data.assignee?.name} />
      ),
    },
    {
      field: 'workingStatus',
      headerName: 'Working Status',
      filter: 'asgSetColumnFilter',
      filterParams: {
        values: ['true', 'false'],
        cellRenderer: (params: any) => {
          if (params.value === '(Select All)') {
            return <div>(Select All)</div>;
          }
          return <QuoteOpen isOpen={params.value} />;
        },
      },
      valueGetter: (params) => params?.data?.PrimaryQuote?.isOpen,
      cellRenderer: (props: { data: RowShape }) => {
        const isOpen = props.data.PrimaryQuote?.isOpen;
        return <QuoteOpen isOpen={isOpen} />;
      },
    },
    {
      field: 'primaryQuoteStatus',
      headerName: 'Quote Status',
      filter: 'asgSetColumnFilter',
      filterParams: {
        values: [
          QuoteStatus.Draft,
          QuoteStatus.Quoted,
          QuoteStatus.Accepted,
          QuoteStatus.Blocked,
          QuoteStatus.Indicated,
          QuoteStatus.Issued,
        ],
        cellRenderer: (params: any) => {
          if (params.value === '(Select All)') {
            return <div>(Select All)</div>;
          }
          return (
            <QuoteBadgeStatus
              size="sm"
              status={params.value}
              shouldDisplayIndicatedLostReason={false}
              shouldDisplayQuotedLostReason={false}
            />
          );
        },
      },
      valueGetter: (params) => params?.data?.PrimaryQuote?.status,
      cellRenderer: (props: { data: RowShape }) => {
        const primaryQuoteStatus = props.data.PrimaryQuote?.status;

        if (isNil(primaryQuoteStatus)) {
          return null;
        }
        return (
          <QuoteBadgeStatus
            size="sm"
            status={primaryQuoteStatus}
            shouldDisplayIndicatedLostReason={false}
            shouldDisplayQuotedLostReason={false}
          />
        );
      },
    },
    { field: 'productLabel', headerName: 'Product' },
    {
      field: 'indicationTime',
      headerName: 'Time To Indicate',
      valueGetter: (params) => params.data?.PrimaryQuote?.timeToIndicated,
      cellRenderer: (props: { data: RowShape }) => {
        return (
          <TimeToIndicateBadge
            timeToIndicated={props.data?.PrimaryQuote.timeToIndicated}
            isOverdue={props.data?.PrimaryQuote.isOverdue}
            indicationDueInHours={props.data?.PrimaryQuote.indicationDueInHours}
            badgeSize="sm"
          />
        );
      },
    },
  ];

  const defaultColDef: ColDef = {
    minWidth: 50,
    editable: false,
    autoHeight: true,
    resizable: true,
    sortable: true,
    menuTabs: ['filterMenuTab'],
    filter: 'agTextColumnFilter',
    // pass in additional parameters to the text filter
    filterParams: {
      buttons: ['reset', 'apply', 'clear', 'cancel'],
      debounceMs: 200,
    },
    flex: 1,
  };

  const onGridReady = (params: GridReadyEvent) => {
    autoSizeAll(params.columnApi);
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 200px)',
        flex: 'column',
        marginBottom: '15px',
      }}
    >
      <div style={{ width: '100%', height: '100%' }} className="ag-theme-balham">
        <AgGridReact
          rowData={myPoliciesQuery?.myOpenPolicies ?? EMPTY_ARRAY}
          columnDefs={colDef}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          suppressMenuHide={true}
          animateRows={true}
          rowBuffer={200}
        />
      </div>
    </div>
  );
};

export default MyOpenPoliciesGrid;
