import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { FunctionComponent, useMemo } from 'react';
import QuoteBadgeStatus from '../Underwriter/QuoteStatusBadge';
import Assignee from '../Assignee';
import Link from 'next/link';
import { Anchor, Badge } from '@mantine/core';
import { isEmpty, isNil } from 'lodash';
import {
  PaginatedPoliciesQuery,
  usePaginatedPoliciesLazyQuery,
  usePolicyFilterOptionsQuery,
} from '../../graphql/operations/PolicyGrid.generated';
import 'ag-grid-enterprise';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';
import { autoSizeAll } from '../InputGrid/InputGrid';
import { showNotification } from '@mantine/notifications';
import { PolicyProduct, QuoteStatus } from '../../graphql/types.generated';
import { TimeToIndicateBadge } from '../TimeToIndicateBadge';
import SalesforceIndicator from '../Salesforce/SalesforceIndicator';

type RowShape = PaginatedPoliciesQuery['paginatedPolicies'][0];

const getDataSource = (
  fetchPolicies: ReturnType<typeof usePaginatedPoliciesLazyQuery>[0]
): IServerSideDatasource => ({
  getRows: async function (params: IServerSideGetRowsParams<RowShape>) {
    // start row is the first index of the current page
    const startRow = params.request.startRow ?? 0;
    const endRow = params.request.endRow;

    const skip = startRow;
    // take either the difference between end and start rows or 100 if no end row is provided by ag grid
    const take = (endRow ?? startRow + 100) - startRow;

    const toSort: Record<string, 'ASC' | 'DESC'> = {};
    params.request.sortModel.forEach((field) => {
      if (field.colId === 'effectiveDate') {
        toSort.sortDate = field.sort === 'asc' ? 'ASC' : 'DESC';
      }
    });

    const toFilter: Record<string, string[]> = {};
    if (!isNil(params.request.filterModel.assignee)) {
      const assigneeSet: string[] = params.request.filterModel.assignee.values.map(
        (assignee: string) => assignee.split(':')[1]
      );
      toFilter.assigneeIdSet = assigneeSet;
    }

    if (!isNil(params.request.filterModel.brokerage)) {
      toFilter.brokerageIdSet = params.request.filterModel.brokerage.values;
    }

    if (!isNil(params.request.filterModel.broker)) {
      toFilter.brokerIdSet = params.request.filterModel.broker.values;
    }

    if (!isNil(params.request.filterModel.productLabel)) {
      toFilter.productSet = params.request.filterModel.productLabel.values;
    }

    if (!isNil(params.request.filterModel.primaryQuoteStatus)) {
      toFilter.quoteStatusSet = params.request.filterModel.primaryQuoteStatus.values;
    }

    const response = await fetchPolicies({
      variables: {
        input: {
          skip,
          take,
          sortDate: 'DESC',
          ...toSort,
          ...toFilter,
        },
      },
    });

    if (!isNil(response.error)) {
      showNotification({
        message: 'there are sharks in the servers, contact the engineers',
        color: 'red',
      });
      params.fail();
    }

    // necessary for proper rendering
    const rows = response.data?.paginatedPolicies ?? EMPTY_ARRAY;
    let rowCount: number | undefined = undefined;

    // no more data - stop paginating
    if (isEmpty(rows)) {
      rowCount = 0;
    }

    // only need to return on last page if the new number of rows is less than the rows to render on the page
    if (!isNil(endRow) && startRow + rows.length < endRow) {
      // rowCount = the first index of the current page + the rows returned
      rowCount = startRow + rows.length;
    }

    return params.success({ rowData: rows, rowCount: rowCount });
  },
});

const PaginatedPolicyGrid: FunctionComponent = () => {
  const { data: policyFilterOptions } = usePolicyFilterOptionsQuery({
    variables: { archivedBrokerages: false },
  });

  const [fetchPolicies] = usePaginatedPoliciesLazyQuery();
  const dataSource = useMemo(() => getDataSource(fetchPolicies), [fetchPolicies]);

  const brokerageIds = useMemo(
    () => policyFilterOptions?.brokerages.map((brokerage) => brokerage.id) ?? EMPTY_ARRAY,
    [policyFilterOptions?.brokerages]
  );

  const brokerageIdToName: Record<string, string> = useMemo(
    () =>
      policyFilterOptions?.brokerages.reduce(
        (agg, curr) => ({ ...agg, [curr.id]: curr.name }),
        {}
      ) ?? EMPTY_OBJECT,
    [policyFilterOptions?.brokerages]
  );

  const brokerIds = useMemo(
    () => policyFilterOptions?.allOrganizationMembers.map((member) => member.id),
    [policyFilterOptions?.allOrganizationMembers]
  );

  const brokerIdToName: Record<string, string> = useMemo(
    () =>
      policyFilterOptions?.allOrganizationMembers.reduce(
        (agg, curr) => ({
          ...agg,
          [curr.id]: `${curr.user.firstName} ${curr.user.lastName}`,
        }),
        {}
      ) ?? EMPTY_OBJECT,
    [policyFilterOptions?.allOrganizationMembers]
  );

  const assigneeToIdMap: Record<string, Record<string, string | undefined | null>> = useMemo(
    () =>
      policyFilterOptions?.assignees.reduce<
        Record<string, Record<string, string | undefined | null>>
      >(
        (agg, curr) => ({
          ...agg,
          [`${curr.name}:${curr.id}`]: { name: curr.name, url: curr.imageUrl },
        }),
        {}
      ) ?? EMPTY_OBJECT,
    [policyFilterOptions?.assignees]
  );

  const colDef: ColDef<RowShape>[] = [
    {
      field: 'namedInsured',
      headerName: 'Named Insured',
      minWidth: 150,
      cellRenderer: (props: { data: RowShape }) => (
        <Link legacyBehavior href={`/u/policyholders/${props.data.policyHolderId}`} passHref>
          <Anchor>{props.data.namedInsured}</Anchor>
        </Link>
      ),
    },
    {
      field: 'policyName',
      headerName: 'Policy Name',
      minWidth: 150,
      cellRenderer: (props: { data: RowShape }) => (
        <Link legacyBehavior href={`/u/policies/${props.data.policyId}`} passHref>
          <Anchor>{props.data.policyName}</Anchor>
        </Link>
      ),
    },
    {
      field: 'brokerage',
      headerName: 'Brokerage',
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        values: brokerageIds,
        valueFormatter: (params: any) => {
          return brokerageIdToName[params?.value as unknown as keyof typeof brokerageIdToName];
        },
      },
      cellRenderer: (props: { data: RowShape }) => (
        <Link legacyBehavior href={`/u/brokerages/${props.data.brokerageId}`} passHref>
          <Anchor>{props.data.brokerage}</Anchor>
        </Link>
      ),
    },
    {
      field: 'broker',
      headerName: 'Broker',
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        values: brokerIds,
        valueFormatter: (params: any) => {
          return brokerIdToName[params?.value as unknown as keyof typeof brokerIdToName];
        },
      },
    },
    { field: 'effectiveDate', headerName: 'Effective Date', sortable: true },
    {
      field: 'assignee',
      headerName: 'Assignee',
      cellRenderer: (props: { data: RowShape }) => (
        <Assignee imageUrl={props.data.assignee?.imageUrl} name={props.data.assignee?.name} />
      ),
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        values: policyFilterOptions?.assignees
          .filter((assignee) => !isNil(assignee.name) && !isNil(assignee.id))
          .map((assignee) => `${assignee.name}:${assignee.id}`),
        valueFormatter: (params: any) => {
          return assigneeToIdMap[params?.value as unknown as keyof typeof assigneeToIdMap]
            ?.name as string;
        },
        cellRenderer: (params: any) => {
          if (params.value === '(Select All)') {
            return <div>(Select All)</div>;
          }
          const id = params?.value;
          const name = params.valueFormatted;
          const url = assigneeToIdMap[id as any]?.url;
          return <Assignee imageUrl={url} name={name} />;
        },
      },
    },
    {
      field: 'workingStatus',
      headerName: 'Working Status',
      cellRenderer: (props: { data: RowShape }) => {
        const isOpen = props.data.PrimaryQuote?.isOpen;
        if (isNil(isOpen)) {
          return null;
        }
        if (isOpen) {
          return (
            <Badge size="sm" color="green">
              OPEN
            </Badge>
          );
        }
        return (
          <Badge size="sm" color="blue">
            CLOSED
          </Badge>
        );
      },
    },
    {
      field: 'primaryQuoteStatus',
      headerName: 'Quote Status',
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [
          QuoteStatus.Accepted,
          QuoteStatus.Issued,
          QuoteStatus.Blocked,
          QuoteStatus.Declined,
          QuoteStatus.Discarded,
          QuoteStatus.Draft,
          QuoteStatus.Indicated,
          QuoteStatus.IndicatedLost,
          QuoteStatus.PendingClearance,
          QuoteStatus.Quoted,
          QuoteStatus.QuotedLost,
        ],
      },
      cellRenderer: (props: { data: RowShape }) => {
        const primaryQuoteStatus = props.data.PrimaryQuote?.status;
        if (isNil(primaryQuoteStatus)) {
          return null;
        }
        return (
          <QuoteBadgeStatus
            size="sm"
            status={primaryQuoteStatus}
            shouldDisplayIndicatedLostReason={false}
            shouldDisplayQuotedLostReason={false}
          />
        );
      },
    },
    {
      field: 'productLabel',
      headerName: 'Product',
      menuTabs: ['filterMenuTab'],
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [
          PolicyProduct.ExcessPractice,
          PolicyProduct.ExcessProject,
          PolicyProduct.PrimaryPractice,
          PolicyProduct.PrimaryProject,
          PolicyProduct.PrimaryNonAdmittedPractice,
          PolicyProduct.PrimaryNonAdmittedProject,
        ],
      },
    },
    {
      field: 'indicationTime',
      headerName: 'Time To Indicate',
      valueGetter: (params) => params.data?.PrimaryQuote?.timeToIndicated,
      cellRenderer: (props: { data: RowShape }) => {
        return (
          <TimeToIndicateBadge
            timeToIndicated={props.data?.PrimaryQuote.timeToIndicated}
            isOverdue={props.data?.PrimaryQuote.isOverdue}
            indicationDueInHours={props.data?.PrimaryQuote.indicationDueInHours}
            badgeSize="sm"
          />
        );
      },
    },
    {
      field: 'salesforce',
      headerName: 'Synced in Salesforce',
      cellRenderer: (props: { data: RowShape }) => (
        <SalesforceIndicator salesforceRecordUrl={props.data.salesforceRecordUrl} />
      ),
    },
  ];

  const defaultColDef: ColDef = {
    minWidth: 50,
    editable: false,
    autoHeight: false,
    resizable: true,
    flex: 1,
    menuTabs: [],
  };

  const onGridReady = (params: GridReadyEvent) => {
    autoSizeAll(params.columnApi);
  };

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 200px)',
        flex: 'column',
        marginBottom: '15px',
      }}
    >
      <div style={{ width: '100%', height: '100%' }} className="ag-theme-balham">
        <AgGridReact
          columnDefs={colDef}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationAutoPageSize={true}
          rowModelType={'serverSide'}
          serverSideDatasource={dataSource}
          onGridReady={onGridReady}
          serverSideStoreType={'partial'}
          suppressMenuHide={true}
          animateRows={true}
        />
      </div>
    </div>
  );
};

export default PaginatedPolicyGrid;
