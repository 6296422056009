'use client';

import React from 'react';
import PaginatedPolicyGrid from '../../../components/PaginatedPolicyGrid/PaginatedPolicyGrid';
import { Button, Group, Tabs } from '@mantine/core';
import MyOpenPoliciesGrid from '../../../components/PaginatedPolicyGrid/MyOpenPoliciesGrid';
import { RecentlyViewedType, useRecentlyViewed } from '../../../utils/useRecentlyViewed';
import { useRouter, useSearchParams } from 'next/navigation';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ShortcutTooltip from '../../../components/ShortcutTooltip';
import { openModal } from '@mantine/modals';
import PolicyholderDetailsForm from '../../../components/Forms/Submissions/PolicyholderDetailsForm';
import { IS_DEV } from '../../../constants';

const PolicyTab = {
  MINE: 'myPolicies',
  ALL: 'policyGrid',
} as const;

type PolicyTabType = typeof PolicyTab[keyof typeof PolicyTab];

const parseTab = (tab: unknown): PolicyTabType => {
  if (Object.values(PolicyTab).includes(tab as PolicyTabType)) {
    return tab as PolicyTabType;
  }

  return PolicyTab.MINE;
};

const Policies = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const currentTab = parseTab(searchParams?.get('tab'));

  const viewed = useRecentlyViewed();
  const recentlyViewedQuotes = viewed.filter((item) => item.type === RecentlyViewedType.quote);
  const redirectToLastViewedQuote = () => router.push(recentlyViewedQuotes[0].url);

  return (
    <>
      <PageHeader
        title={{
          label: 'Policies',
        }}
        rightSection={
          <Group>
            {IS_DEV && (
              <ShortcutTooltip
                key="shortcutReopenLastQuote"
                actionName="Reopen last quote"
                shortcut="R"
              >
                <Button
                  id="reopen-last-quote-policyholders"
                  variant="subtle"
                  onClick={() => redirectToLastViewedQuote()}
                >
                  View your last quote
                </Button>
              </ShortcutTooltip>
            )}
            <ShortcutTooltip key="shortcutTooltip" actionName="Create Policyholder" shortcut="C">
              <Button
                key="new"
                onClick={() =>
                  openModal({
                    modalId: 'policyholderForm',
                    title: 'New Policyholder',
                    children: <PolicyholderDetailsForm />,
                  })
                }
              >
                Create Policyholder
              </Button>
            </ShortcutTooltip>
          </Group>
        }
      />
      <Tabs
        value={currentTab}
        onChange={(value) => {
          if (value) {
            const newParams = new URLSearchParams();
            newParams.set('tab', value);
            router.replace(`?${newParams.toString()}`, { scroll: false });
          }
        }}
      >
        <Tabs.List>
          <Tabs.Tab value={PolicyTab.MINE}>My Policies</Tabs.Tab>
          <Tabs.Tab value={PolicyTab.ALL}>All Policies</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="myPolicies">
          <MyOpenPoliciesGrid />
        </Tabs.Panel>
        <Tabs.Panel value="policyGrid">
          <PaginatedPolicyGrid />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default Policies;
