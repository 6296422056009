import { Anchor } from '@mantine/core';
import React, { ReactNode } from 'react';
import { isEmpty } from 'lodash';

const SalesforceIndicator = ({
  salesforceRecordUrl,
  children = 'View',
}: {
  salesforceRecordUrl?: string | null;
  children?: ReactNode;
}) => {
  return !isEmpty(salesforceRecordUrl) ? (
    <Anchor
      onClick={async () => window.open(salesforceRecordUrl as string)}
      variant="outline"
      target="_blank"
      c="blue"
    >
      {children}
    </Anchor>
  ) : null;
};

export default SalesforceIndicator;
