import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { $TsFixMe } from '../../module';

export const composeValidators =
  (...validators: $TsFixMe) =>
  (value: $TsFixMe, allValues: $TsFixMe) =>
    validators.reduce(
      (error: $TsFixMe, validator: $TsFixMe) => error || validator(value, allValues),
      undefined
    );
export const composeValidatorsHookForm =
  (...validators: $TsFixMe) =>
  (value: $TsFixMe) =>
    validators.reduce(
      (error: $TsFixMe, validator: $TsFixMe) => error || validator(value),
      undefined
    );

export const validateRequired = (value: unknown) => {
  if (value === undefined) {
    return undefined;
  }
  if (value === '' || value === null) {
    return 'Required';
  }
  return undefined;
};

export const validateNullOrNone = (value: unknown) => {
  if (value === 'NONE') {
    return undefined;
  }

  if (value === undefined) {
    return undefined;
  }
  if (value !== null) {
    return 'This must be empty';
  }
  return undefined;
};

export const validateNull = (value: unknown) => {
  if (value === undefined) {
    return undefined;
  }
  if (value !== null) {
    return 'This must be empty';
  }
  return undefined;
};

export const validateZipCode = (zipCode: string) => {
  if (zipCode) {
    const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    return re.test(zipCode) ? undefined : 'Invalid Zip Code';
  }
};

export const validateExpirationDate = (value: string, effectiveDate: $TsFixMe) => {
  const inputDate = dayjs(value);
  return inputDate.isAfter(effectiveDate) === true && effectiveDate
    ? undefined
    : 'Date must be after Effective Date';
};

export const validateGreaterOrEqualToZero = (value: number) => {
  if (isNil(value)) {
    return undefined;
  }

  if (value >= 0) {
    return undefined;
  }

  return 'Value must be greater or equal to zero';
};

export const validateGreaterThanZero = (value: number) => {
  if (isNil(value)) {
    return undefined;
  }

  if (value > 0) {
    return undefined;
  }
  return 'Value must be greater than zero';
};

export const validateBetweenZeroAndOneHundred = (value: number) => {
  if (isNil(value)) {
    return undefined;
  }

  if (value >= 0 && value <= 100) {
    return undefined;
  }

  return 'Value must be between 0 and 100';
};

export const validateOlderThanToday = (value: string) => {
  const inputDate = dayjs(value);
  const currentDate = dayjs();
  const earliestValidDate = dayjs('1/1/2010');

  if (inputDate.isSameOrAfter(currentDate) && value) {
    return 'Date must be in the past';
  }

  if (inputDate.isBefore(earliestValidDate) && value) {
    return 'Date must be after 1/1/2010';
  }
  return undefined;
};

export const validateNumberRequiredAndGreaterThanZero = composeValidatorsHookForm(
  validateRequired,
  validateGreaterThanZero
);
export const validateNumberRequiredAndGreaterThanOrEqualZero = composeValidatorsHookForm(
  validateRequired,
  validateGreaterOrEqualToZero
);

export const validateDateRequiredAndOlderThanToday = composeValidatorsHookForm(
  validateRequired,
  validateOlderThanToday
);

export const rulesRequiredOnly = { required: 'Required' };

export const rulesRequiredAndGreaterThanOrEqualToZero = {
  required: 'Required',
  min: { message: 'Value must be greater or equal to zero', value: 0 },
};
export const rulesRequiredAndOlderThanToday = {
  required: 'Required',
  validate: validateOlderThanToday,
};

export const radioValue = (value?: boolean) => {
  if (value === true) {
    return 'true';
  }

  if (value === false) {
    return 'false';
  }

  return undefined;
};
